<script>
import SorterSelectorBase from "./Base";
import EmployeeAvatar from "@/components/employees/Avatar";
import EmployeeSelect from "@/components/util/RaiEmployeeSelect";
import Stopwatch from "@/components/util/Stopwatch";

import { format } from "date-fns";
import { get } from "lodash";

export default {
  name: "SorterSelectorMobile",
  components: {
    EmployeeAvatar,
    EmployeeSelect,
    Stopwatch,
  },
  extends: SorterSelectorBase,
  methods: {
    format,
    get,
  },
};
</script>

<template>
  <div class="d-flex align-center">
    <!-- employee avatar and timer -->
    <template v-if="!hideAvatar">
      <EmployeeSelect
        v-if="isEditable"
        :loading="loading"
        :value="employee"
        :employees="clockedInEmployees"
        size="32"
        @input="changeSorter"
      />
      <EmployeeAvatar v-else :employee-id="employee.id" size="32" />
    </template>
    <div>Sorting</div>
    <Stopwatch class="ml-2 rai-data" :start="buy.startedAt" />
    <div class="text-caption ml-3 grey--text">
      Due
      {{
        buy.estimatedPickupAt
          ? $filters.dateTime.relativeTime(buy.estimatedPickupAt)
          : "date not defined"
      }}
    </div>
    <!-- <span class="ml-2 rai-data" v-text="get(buy, 'sortTime')" /> -->
  </div>
</template>
