<script>
import BuyNumber from "./BuyNumber";
import VxMoreMenu from "@/components/vx/VxMoreMenu";
import { formatNumber } from "@/utils/phonenumber";

export default {
  name: "BuyDetailToolbarTitle",
  components: {
    BuyNumber,
    VxMoreMenu,
  },
  props: {
    transId: { type: String, default: undefined },
    fullName: { type: String, default: undefined },
    phoneNumber: { type: String, default: undefined },
    flagged: { type: Boolean, default: false },
    reprintDisabled: { type: Boolean, default: true },
  },
  computed: {
    title() {
      if (!this.fullName && !this.phoneNumber) {
        return undefined;
      }

      return this.fullName || formatNumber(this.phoneNumber);
    },
  },
};
</script>

<template>
  <v-toolbar-title
    class="d-flex justify-start align-center flex-shrink-1 flex-grow-1"
  >
    <div class="number-name-wrapper mr-auto d-flex justify-start align-center">
      <!-- $Buy # and customer name -->
      <BuyNumber v-if="transId" :trans-id="transId" class="mr-4" />

      <div v-if="title" class="name-wrapper">
        {{ title }}
        <v-icon v-if="flagged" class="ml-1" size="16"
          >$vuetify.icons.flag</v-icon
        >
      </div>
    </div>

    <div v-if="!transId && !title" class="mr-auto">Loading</div>

    <VxMoreMenu>
      <slot name="menu">
        <v-list>
          <v-list-item @click="$emit('edit')" v-text="`Edit buy`" />
          <v-list-item
            :disabled="reprintDisabled"
            @click="$emit('reprintSlips')"
            v-text="`Reprint slips`"
          />
          <v-list-item
            @click="$emit('viewCustomer')"
            v-text="`View customer`"
          />
        </v-list>
      </slot>
    </VxMoreMenu>
  </v-toolbar-title>
</template>
