<template>
  <div class="rai-data">
    <span class="font-weight-bold" v-text="number" /> -
    <span class="font-weight-bold" v-text="description" />
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: [String, Number],
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>
