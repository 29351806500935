<script>
export default {
  name: "BuyDetailToolbarExtension",
  components: {},
  props: {
    status: { type: String, default: undefined },
    tab: { type: String, default: undefined },
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showTimeline() {
      if (this.status === "pending" || this.status === "submitted") {
        return false;
      }

      if (this.status === "open" || this.status === "complete") {
        if (this.isSmallScreen) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<template #toolbar-extension>
  <v-tabs
    :value="tab"
    grow
    centered
    class="raiTabs"
    background-color="transparent"
    show-arrows
    @change="$emit('changeTab', $event)"
  >
    <v-tab href="#buy" ripple v-text="`Buy Details`" />
    <v-tab v-if="showTimeline" href="#timeline" ripple v-text="`Timeline`" />
    <v-tab href="#message" ripple v-text="`Messaging`" />
  </v-tabs>
</template>
