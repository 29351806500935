var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VxDialogView',{attrs:{"retain-focus":false,"v-size":'large',"h-size":'medium',"error-toolbar":_vm.buy && _vm.buy.customer && _vm.buy.customer.flagged},scopedSlots:_vm._u([(!_vm.isSmallScreen)?{key:"actions",fn:function(){return [(_vm.showCompleteButton)?_c('CompleteButton',{attrs:{"color":"primary","buy":_vm.buy,"buy-id":_vm.buy.id,"disabled":_vm.completeDisabled}}):_vm._e()]},proxy:true}:null,(_vm.isSmallScreen && _vm.showCompleteButton)?{key:"large-actions",fn:function(){return [(_vm.showCompleteButton)?_c('CompleteButton',{attrs:{"buy":_vm.buy,"buy-id":_vm.buy.id,"disabled":_vm.completeDisabled}}):_vm._e()]},proxy:true}:null,{key:"toolbar-title",fn:function(){return [_c('BuyDetailToolbarTitle',_vm._b({on:{"viewCustomer":_vm.handleViewCustomer,"reprintSlips":_vm.handleReprint,"edit":_vm.handleEdit}},'BuyDetailToolbarTitle',{
        ..._vm.buy,
        reprintDisabled: _vm.reprintDisabled,
        fullName: _vm.buy && _vm.buy.customer ? _vm.buy.customer.fullName : undefined,
        phoneNumber:
          _vm.buy && _vm.buy.customer ? _vm.buy.customer.phoneNumber : undefined,
      },false))]},proxy:true},{key:"toolbar-extension",fn:function(){return [_c('BuyDetailToolbarExtension',_vm._b({on:{"changeTab":_vm.changeTab}},'BuyDetailToolbarExtension',{ ..._vm.buy, tab: _vm.tab },false))]},proxy:true}],null,true)},[[(_vm.buy)?_c('v-tabs-items',{staticClass:"BuyDetail__TabItems",attrs:{"grow":"","slider-color":"secondary","touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"fill-height",attrs:{"value":"buy"}},[(_vm.buy.status === 'open')?_c('div',[_c('BuyDialogOpenContent',{attrs:{"show-edit":_vm.showEdit,"store-id":_vm.storeId,"customer-id":(_vm.buy && _vm.buy.customerId) || '',"buy-status":_vm.buy.status,"buy-id":_vm.buy.id},on:{"loading":(v) => (_vm.openContentLoading = v),"update:customerField":_vm.updateCustomerField,"update:buyField":_vm.updateBuyField}})],1):_vm._e(),(_vm.buy.status === 'complete')?_c('div',[_c('BuyDialogCompleteContent',{attrs:{"show-edit":_vm.showEdit,"store-id":_vm.storeId,"customer-id":(_vm.buy && _vm.buy.customerId) || '',"buy-status":_vm.buy.status,"buy-id":_vm.buy.id},on:{"loading":(v) => (_vm.completeContentLoading = v)}})],1):_vm._e(),(_vm.buy.status === 'closed')?_c('div',[_c('BuyDialogClosedContent',{attrs:{"show-edit":_vm.showEdit,"store-id":_vm.storeId,"customer-id":(_vm.buy && _vm.buy.customerId) || '',"buy-status":_vm.buy.status,"buy-id":_vm.buy.id},on:{"loading":(v) => (_vm.completeContentLoading = v)}})],1):_vm._e()]),_c('v-tab-item',{staticClass:"px-3 fill-height",attrs:{"value":"timeline"}},[_c('BuyTimeline',{attrs:{"buy-id":_vm.computedBuyId}})],1),_c('v-tab-item',{staticClass:"fill-height",attrs:{"value":"message"}},[_c('BuyDialogMessaging',{staticClass:"fill-height",attrs:{"customer-id":_vm.buy.customerId || undefined}})],1)],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }