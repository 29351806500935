<script>
import { cleanPhoneNumber, phoneNumberValidFull } from "@/utils/phone";
import { validationMixin } from "vuelidate";
import { VueMaskDirective } from "v-mask";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import { lowerFirst, get } from "lodash";
import { hasSnackbarAccess } from "@/mixins/ui";
import { states } from "@/utils/lib";
import { VxPhoneField } from "@/core-ui";

export default {
  name: "BuyDialogCustomerForm",
  directives: { mask: VueMaskDirective },
  components: {
    VxPhoneField,
  },
  mixins: [validationMixin, hasSnackbarAccess],
  inheritAttrs: false,
  inject: ["registerBuyDialogForm"],
  stateItems: Object.values(states()).sort(),

  props: {
    store: {
      type: Object,
      default: () => undefined,
    },
    customerId: {
      type: String,
      default: null,
    },
    firstName: {
      type: String,
      default: null,
    },
    lastName: {
      type: String,
      default: null,
    },
    phoneNumber: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    driverLicense: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: "",
    },
    licenseVerified: {
      type: Boolean,
    },
    flagged: {
      type: Boolean,
      default: false,
    },
    flagReason: {
      type: String,
      default: undefined,
    },

    idVerifyNumber: {
      type: String,
      default: "",
    },
    idVerifyLoading: {
      type: Boolean,
    },
    idVerifyErrors: {
      type: Array,
      default: () => [],
    },

    idNumber: {
      type: String,
      default: "",
    },
    idState: {
      type: String,
      default: "",
    },
    idDataUpdateLoading: {
      type: Boolean,
    },
  },
  data: (vm) => {
    return {
      localFirstName: vm.firstName,
      localLastName: vm.lastName,
      localPhoneNumber: vm.phoneNumber.replace(/^\+1/, ""),
      localEmail: vm.email,
      localIdNumber: undefined,
      localIdState: states()[vm.idState],
      localIdVerifyLast4: undefined,
      loading: [],
      menu: false,
      overwriteLicense: false,
      renderKey: 0,
    };
  },
  validations: {
    localFirstName: {
      required,
      minLength: minLength(2),
    },
    localLastName: {
      required,
      minLength: minLength(2),
    },
    localEmail: {
      email,
      required: requiredIf(function () {
        return get(this, "store.buyConfig.buysRequireEmail");
      }),
    },
    localPhoneNumber: {
      required,
      phone(val) {
        if (!val || (val && val.length === 0)) return false;

        const cleanedPhoneNumber = cleanPhoneNumber(val);
        return phoneNumberValidFull(cleanedPhoneNumber);
      },
    },
    localIdNumber: {
      // This field should only be required if the store requires licenses
      // AND the customer has no ID on file.
      // We should make this better eventually. This is just a stop-gap to get
      // Sarah's stores through this weekend.
      required: requiredIf(function () {
        return (
          get(this, "store.buyConfig.buysRequireLicense", false) &&
          !get(this, "driverLicense.id")
        );
      }),
    },
    localIdState: {
      // Same as localIdNumber
      required: requiredIf(function () {
        return (
          get(this, "store.buyConfig.buysRequireLicense", false) &&
          !get(this, "driverLicense.id")
        );
      }),
    },
    localIdVerifyLast4: {
      // Oposite of localIdNumber
      required: requiredIf(function () {
        return (
          get(this, "store.buyConfig.buysRequireLicense", false) &&
          get(this, "driverLicense.id") &&
          !get(this, "licenseVerified")
        );
      }),
      minLength: minLength(4),
    },
    idVerified: {
      required: requiredIf(function () {
        return (
          get(this, "store.buyConfig.buysRequireLicense", false) &&
          get(this, "driverLicense.id") &&
          (get(this, "status") === "pending" ||
            get(this, "status") === "submitted") &&
          !get(this, "licenseVerified")
        );
      }),
    },
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      const field = this.$v.localFirstName;
      !field.required && errors.push("First name is required.");
      !field.minLength && errors.push("First name is too short.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      const field = this.$v.localLastName;
      !field.required && errors.push("Last name is required.");
      !field.minLength && errors.push("Last name is too short.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      const field = this.$v.localEmail;
      !field.required && errors.push("Email is required.");
      !field.email && errors.push("Email must be valid.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      const field = this.$v.localPhoneNumber;
      !field.required && errors.push("Phone is required.");
      !field.phone && errors.push("Must be a valid phone.");
      return errors;
    },
    idNumberErrors() {
      const errors = [];
      const field = this.$v.localIdNumber;
      !field.required && errors.push("ID Number is required.");
      return errors;
    },
    idStateErrors() {
      const errors = [];
      const field = this.$v.localIdState;
      !field.required && errors.push("ID State is required.");
      return errors;
    },
    idVerifyLast4Errors() {
      let errors = [...this.idVerifyErrors];

      const field = this.$v.localIdVerifyLast4;
      if (field.required && field.minLength) return errors;
      return [...errors, "Enter the last 4 digits of the customer's ID number"];
    },
    computedLoading() {
      return {
        localFirstName: this.loading.includes("localFirstName"),
        localLastName: this.loading.includes("localLastName"),
        localPhoneNumber: this.loading.includes("localPhoneNumber"),
        localEmail: this.loading.includes("localEmail"),
        localIdNumber: this.loading.includes("localIdNumber"),
        localIdState: this.loading.includes("localIdState"),
        localIdVerifyLast4: this.loading.includes("localIdVerifyLast4"),
      };
    },
    // Returns true if the store requires licenses for buys -
    // AND there is no license on file.
    shouldShowIdNumberField() {
      return (
        !get(this, "driverLicense.id") || get(this, "overwriteLicense", false)
      );
    },
    // If the customer has a license that has not yet been verified for this buy,
    // show the last4 verification component
    shouldShowVerifyLast4() {
      return (
        get(this, "driverLicense.id") &&
        (get(this, "status") === "pending" ||
          get(this, "status") === "submitted") &&
        !get(this, "licenseVerified", false) &&
        !get(this, "overwriteLicense", false)
      );
    },
    shouldShowCancelOverwrite() {
      return this.overwriteLicense;
    },
    // If the customer has a license that has been verified for this buy,
    // show that there is one attached
    shouldShowIdAttached() {
      return (
        get(this, "driverLicense.id") &&
        (get(this, "status") === "pending" ||
          get(this, "status") === "submitted") &&
        get(this, "licenseVerified", false) &&
        !get(this, "overwriteLicense", false)
      );
    },
    // Public
    isValid() {
      return !this.$v.$invalid;
    },
  },
  watch: {
    // Anytime the `customerId` changes, trigger validation.
    // This way, any invalid fields are immediately apparent
    //  and submitting is disabled.
    customerId() {
      this.$v.$touch();
    },
    // Emit loading events
    loading(v) {
      this.$emit("loading", !!v.length);
    },
    idNumber(newValue) {
      if (newValue) {
        this.localIdNumber = newValue;
      }
    },
    idDataUpdateLoading(newValue) {
      if (newValue) {
        if (!this.computedLoading["localIdNumber"])
          this.loading.push("localIdNumber");
        if (!this.computedLoading["localIdState"])
          this.loading.push("localIdState");
      } else {
        this.loading.splice(this.loading.indexOf("localIdNumber"), 1);
        this.loading.splice(this.loading.indexOf("localIdState"), 1);
      }
    },
    // If the cache is updated, make sure we update the text field model
    firstName(v) {
      if (v) {
        this.localFirstName = v;
      }
    },
    // If the cache is updated, make sure we update the text field model
    lastName(v) {
      if (v) {
        this.localLastName = v;
      }
    },
    // If the cache is updated, make sure we update the text field model
    email(v) {
      this.localEmail = v;
    },
  },
  mounted() {
    // Once we're live, tell the parent
    this.registerBuyDialogForm("customerForm", this);
  },
  methods: {
    // Turns `localFirstName` into `firstName`
    transformFieldToInputKey(field) {
      return lowerFirst(field.replace(/^local/, ""));
    },
    // Normalizes phone prop and data, then checks
    // to see if they differ
    phoneValuesDiffer() {
      const prop = this.phoneNumber.replace(/^\+?1?/, "").replace(/[^\d]/g, "");
      const data = this.localPhoneNumber
        .replace(/^\+?1?/, "")
        .replace(/[^\d]/g, "");
      return prop !== data;
    },
    // Returns `true` if the prop value and data value for the given field differ.
    comparePropToData(field) {
      if (/phone/i.test(field)) return this.phoneValuesDiffer();

      return this[field] !== this[this.transformFieldToInputKey(field)];
    },
    // Update the customer on blur, if the field is valid
    handleBlur(field) {
      // Ensure the field passes validation AND the field value has changed
      // (No need to update if the new value is the same as the original value)
      if (!this.$v[field].$invalid && this.comparePropToData(field)) {
        // Toggle loading on the field
        if (!this.computedLoading[field]) this.loading.push(field);
        this.$emit(
          "update:field",
          this.transformFieldToInputKey(field),
          this[field],
          () => {
            // Stop loading
            this.loading.splice(this.loading.indexOf(field), 1);
          }
        );
      }
    },
    // This is in a separate mutation because it's not a simple
    // update. We have to create a
    handleIdBlur(field) {
      // Guard. Don't save anything if any of the elements are falsy
      // customerId, localIdNumber, localIdState
      if (
        [!this.customerId, !this.localIdNumber, !this.localIdState].some(
          (e) => e
        )
      )
        return false;

      if (!this.computedLoading[field]) this.loading.push(field);

      this.$emit(
        "update:idField",
        {
          number: this.localIdNumber,
          state: this.localIdState,
        },
        () => {
          // Stop loading
          this.loading.splice(this.loading.indexOf(field), 1);
        }
      );
    },

    handleIdVerifyInput() {
      if (!this.$v["localIdVerifyLast4"].$invalid) {
        if (!this.computedLoading["localIdVerifyLast4"])
          this.loading.push("localIdVerifyLast4");

        this.$emit("update:idVerifyField", this.localIdVerifyLast4, () => {
          // Stop loading
          this.loading.splice(this.loading.indexOf("localIdVerifyLast4"), 1);
        });
      }
    },

    // Returns true if status !== pending && !!fieldValue
    // Used to only allow *adding* customer info that doesn't
    // already exist. *Editing* is still not allowed.
    fieldIsDisabled(status, fieldValue) {
      return !(status === "pending" || !fieldValue);
    },
    enableOverwriteLicense() {
      this.overwriteLicense = true;
    },
    cancelOverwriteLicense() {
      this.overwriteLicense = false;
    },
  },
};
</script>

<template>
  <div class="buy-dialog-customer-form">
    <div class="d-flex">
      <div class="flex-grow-1 customer-field mr-3">
        <!-- first name -->
        <v-text-field
          v-model="localFirstName"
          dense
          label="First name"
          :loading="computedLoading.localFirstName"
          :error-messages="firstNameErrors"
          autocomplete="off"
          :disabled="fieldIsDisabled(status, firstName)"
          @input="$v.localFirstName.$touch()"
          @blur="handleBlur('localFirstName', $event)"
        />
      </div>
      <div class="flex-grow-1 customer-field">
        <!-- last name -->
        <v-text-field
          v-model="localLastName"
          dense
          label="Last name"
          :loading="computedLoading.localLastName"
          :error-messages="lastNameErrors"
          autocomplete="off"
          :disabled="fieldIsDisabled(status, lastName)"
          @input="$v.localLastName.$touch()"
          @blur="handleBlur('localLastName', $event)"
        />
      </div>
    </div>
    <div class="d-flex mt-sm-3 mt-1">
      <div class="flex-grow-1 customer-field mr-3">
        <VxPhoneField
          v-model="localPhoneNumber"
          dense
          label="Phone number"
          :loading="computedLoading.localPhoneNumber"
          :error-messages="phoneErrors"
          autocomplete="off"
          :disabled="fieldIsDisabled(status, phoneNumber)"
          @input="$v.localPhoneNumber.$touch()"
          @blur="handleBlur('localPhoneNumber', $event)"
        />
      </div>

      <div class="flex-grow-1 customer-field">
        <v-text-field
          v-model="localEmail"
          dense
          label="Email"
          :loading="computedLoading.localEmail"
          :error-messages="emailErrors"
          type="email"
          autocomplete="off"
          :disabled="fieldIsDisabled(status, email)"
          @input="$v.localEmail.$touch()"
          @blur="handleBlur('localEmail', $event)"
        />
      </div>
    </div>
    <div v-if="shouldShowVerifyLast4" class="d-flex mt-sm-3 mt-1 align-center">
      <div class="flex-grow-1 customer-field mr-3">
        <v-text-field
          v-model="localIdVerifyLast4"
          dense
          label="Last 4 digits of ID"
          :loading="computedLoading.localIdVerifyLast4"
          :error-messages="idVerifyLast4Errors"
          autocomplete="off"
          :hint="`Enter the last 4 digits of the customer's ID number`"
          required
          @input="handleIdVerifyInput"
        />
      </div>
    </div>
    <div v-if="shouldShowVerifyLast4" class="d-flex mt-sm-3 mt-1 align-center">
      <div class="flex-grow-1 customer-field">
        <!-- Overwrite License -->
        <v-btn
          text
          color="secondary"
          @click="enableOverwriteLicense"
          v-text="`Add new license`"
        />
      </div>
    </div>
    <div
      v-else-if="shouldShowIdNumberField"
      class="d-flex mt-sm-3 mt-1 align-center"
    >
      <div class="flex-grow-1 customer-field mr-3">
        <v-text-field
          key="id-number"
          v-model="localIdNumber"
          dense
          label="ID Number"
          :loading="computedLoading.localIdNumber"
          :error-messages="idNumberErrors"
          autocomplete="no"
          @input="$v.localIdNumber.$touch()"
          @blur="handleIdBlur('localIdNumber')"
        />
      </div>
      <div class="flex-grow-1 customer-field">
        <v-autocomplete
          key="id-state"
          v-model="localIdState"
          dense
          label="ID State"
          :items="$options.stateItems"
          :loading="computedLoading.localIdState"
          :error-messages="idStateErrors"
          autocomplete="no"
          @input="$v.localIdState.$touch()"
          @blur="handleIdBlur('localIdState')"
        />
      </div>
    </div>
    <!-- Cancel overwrite license -->
    <div
      v-if="shouldShowCancelOverwrite"
      class="d-flex mt-sm-3 mt-1 align-center"
    >
      <div>
        <v-btn text @click="cancelOverwriteLicense" v-text="`Cancel`" />
      </div>
    </div>
    <div v-if="shouldShowIdAttached" class="d-flex">
      <h5 class="text-h6 mr-6 mt-4">License attached</h5>
    </div>
    <v-tooltip v-if="flagged" bottom>
      <template #activator="{ on, attrs }">
        <div
          class="error--text text-truncate mt-sm-3 mt-1"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="1em" class="error--text mr-2"
            >$vuetify.icons.flag</v-icon
          >{{ flagReason }}
        </div>
      </template>
      <template>{{ flagReason }}</template>
    </v-tooltip>
  </div>
</template>

<style lang="scss">
.customer-field {
  flex-basis: 50%;
}

.buy-dialog-customer-form .v-text-field.v-input--dense {
  padding-top: 12px;
}
</style>
