<template>
  <div class="d-flex align-center">
    <StorePrewrittenTextSelect v-model="storeMessageId" />
    <v-btn
      class="buy-dialog__action-button--checkin elevation-0 ml-3"
      text
      :color="color"
      v-bind="$attrs"
      :loading="loading"
      @click="handleClick"
      v-text="`Complete`"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { injectActiveEmployee } from "@/mixins/employee";
import { hasSnackbarAccess } from "@/mixins/ui";

const query = gql`
  query ACTION_BUTTON_COMPLETE_BUY($id: ID!) {
    buy(id: $id) {
      id
      containerNum
      containerDescription
      signatureUrl
      checkedInAt
      customer {
        id
        drsCustomerCode
        firstName
        lastName
        phoneNumber
        email
        loyalty
      }
    }
  }
`;

const prewrittenTextsQuery = gql`
  query ACTION_BUTTON_COMPLETE_BUY_PREWRITTEN_TEXTS {
    prewrittenTexts {
      id
      slug
    }
  }
`;

const mutation = gql`
  mutation ACTION_BUTTON_COMPLETE_BUY($id: ID!, $storeMessageId: ID) {
    completeBuyNewFlow(id: $id, storeMessageId: $storeMessageId) {
      buy {
        id
        status
        completedAt
      }
      errors
    }
  }
`;

export default {
  components: {
    StorePrewrittenTextSelect: () =>
      import("@/components/StorePrewrittenTextSelect"),
  },
  mixins: [injectActiveEmployee, hasSnackbarAccess],
  inheritAttrs: false,
  query,
  prewrittenTextsQuery,
  inject: ["hideDialog"],
  props: {
    buyId: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "accent",
    },
  },
  data: () => ({
    buy: undefined,
    loading: false,
    storeMessageId: undefined,
  }),
  apollo: {
    buy: {
      fetchPolicy: "cache-and-network",
      query,
      variables() {
        return { id: this.buyId };
      },
      skip() {
        return !this.buyId;
      },
    },
  },
  computed: {},
  methods: {
    // Click handler for button
    async handleClick() {
      this.loading = true;

      // ---- ^Guards
      if (!this.buy) {
        console.log("no buy");
        this.loading = false;
        return;
      }
      // ---- $Guards

      const variables = {
        id: this.buyId,
        storeMessageId: this.storeMessageId,
      };

      try {
        // Call the apollo mutation to complete the buy
        const {
          data: {
            completeBuyNewFlow: { errors },
          },
        } = await this.$apollo.mutate({
          mutation,
          variables,
        });

        // Re-throw backend errors
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }

        // If we made it this far, everything was successful
        this.showSnackbar({
          text: "Completed buy successfully",
        });
        this.hideDialog();
      } catch (error) {
        return this.showSnackbar({
          text: `An error occurred while completing this buy. ${error}`,
        });
      }
      // Toggle loading off
      this.loading = false;
    },
  },
};
</script>
