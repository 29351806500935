<template>
  <VxQuery
    :query="$options.customerThreadQuery"
    :variables="variables"
    fetch-policy="cache-and-network"
    :skip="!customerId"
    @result="handleResult"
  >
    <!-- VxQuery error slot -->
    <template #error>
      <v-row justify="center" class="mt-4 fill-height">
        <v-card flat color="grey--text">
          <v-card-title class="justify-center">
            <v-icon color="grey lighten-1" v-text="`$vuetify.icons.warning`" />
          </v-card-title>
          <v-card-text>
            <p class="text-body-1">
              {{ error.graphQLErrors.map((e) => e.message).join("\n") }}
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </template>
    <!-- VxQuery data slot -->
    <template #data="{ data }">
      <template v-if="get(data, 'customer.messageThread.messages')" tag="span">
        <div class="message-data">
          <MessageThread
            class="message-data__thread px-3"
            :items="get(data, 'customer.messageThread.messages')"
            :quick-messages="prewrittenTexts"
          />
          <MessageInput :loading="sendLoading" @send="send" />
        </div>
      </template>
      <template v-else>Unable to load message thread</template>
    </template>
  </VxQuery>
</template>
<script>
import gql from "graphql-tag";
import get from "lodash/get";
import {
  addMessageToThreadCache,
  MESSAGE_CREATE,
  MESSAGE_SENT_SUBSCRIPTION,
} from "@/views/Texting/AppView/graphql";

const customerThreadQuery = gql`
  query BUY_DIALOG_MESSAGING_CUSTOMER_THREAD($storeId: ID!, $id: ID!) {
    customer(storeId: $storeId, id: $id) {
      id
      messageThread {
        id
        unread
        lastMessage {
          id
        }
        messages {
          # TODO - also use same fragment as for texting!
          id
          body
          cellphone
          createdAt
          msgType
          name
          mediaUrls
          sendStatus
          sendErrorMessage
        }
      }
    }
  }
`;

const quickMessagesQuery = gql`
  query BUY_DIALOG_MESSAGING_PREWRITTEN_TEXTS {
    prewrittenTexts {
      id
      slug
    }
  }
`;

export default {
  name: "BuyDialogMessaging",
  components: {
    VxQuery: () => import("@/components/vx/VxQuery"),
    MessageThread: () =>
      import("@/views/Texting/AppView/components/MessageThread"),
    MessageInput: () =>
      import("@/views/Texting/AppView/components/MessageThreadMessageInput"),
  },
  customerThreadQuery,
  props: {
    customerId: {
      type: String,
      default: "",
    },
  },
  apollo: {
    prewrittenTexts: {
      query: quickMessagesQuery,
    },
    $subscribe: {
      textingMessageSent: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        variables() {
          return {
            storeId: this.$route.params.storeId,
            messageThreadId: this.messageThreadId,
          };
        },
        skip() {
          return !this.$route.params.storeId || !this.messageThreadId;
        },
        result(response) {
          // Important - we need to send this.$apollo.getClient() and not this.$apollo.getClient().cache.
          //  - if we send cache -> the cache will not actually update.
          addMessageToThreadCache(
            this.$apollo.getClient(),
            response.data.textingMessageSent.message,
            this.messageThreadId
          );
        },
      },
    },
  },
  data: () => ({
    prewrittenTexts: [],
    sendLoading: false,
    messageThreadId: undefined,
  }),
  computed: {
    // Variable object for VxQuery
    variables() {
      return {
        storeId: this.$route.params.storeId,
        id: this.customerId,
      };
    },
    // Computed height for MessageThread
    threadHeight() {
      return this.$vuetify.breakpoint.smAndDown
        ? `calc(100vh - 64px - 48px)`
        : `50vh`;
    },
  },
  methods: {
    get,
    // Extract the messageThreadId so we can handle send events on the MessageThreadInput
    handleResult(payload) {
      this.messageThreadId = get(payload, "data.customer.messageThread.id");
    },
    // Handler for MessageInput@send
    async send(body) {
      this.sendLoading = true;
      const vm = this;
      const variables = {
        input: {
          messageThreadId: this.messageThreadId,
          body,
        },
      };
      try {
        await this.$apollo.mutate({
          mutation: MESSAGE_CREATE,
          variables,
          // Update the apollo cache with the new message
          update: (
            store,
            {
              data: {
                createMessage: { message },
              },
            }
          ) => {
            addMessageToThreadCache(store, message, vm.messageThreadId);
          },
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while sending message. ${error}`,
        });
      }
      this.sendLoading = false;
    },
  },
};
</script>
<style lang="scss">
.rai-app-sm,
.rai-app-md,
.rai-app.lg,
.rai-app-xl {
  .message-data {
    height: inherit;
    display: grid;
    grid-template-rows: calc(100% - 52px) 52px;

    .message-data__thread {
      overflow-y: scroll;
    }
  }
}
</style>
