<script>
export default {
  name: "BuyDialogOfferSummary",
  inheritAttrs: false,
  props: {
    customerId: {
      type: String,
      default: undefined,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    quoteItems: {
      type: Number,
      default: 0,
    },
    quoteAmount: {
      type: Number,
      default: 0,
    },
    internalNote: {
      type: String,
      default: undefined,
    },
    sorterId: {
      type: String,
      default: undefined,
    },
    takeReasons: {
      type: Array,
      default: () => [],
    },
    passReasons: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    toggle() {
      this.$emit("update:condensed", !this.condensed);
    },
  },
};
</script>

<template>
  <div>
    <!-- note -->
    <div v-if="!!internalNote">
      <v-icon v-text="`$vuetify.icons.stickyNote`" />
      <span class="ml-3">{{ internalNote }}</span>
    </div>

    <!-- buy reasons -->
    <div class="d-sm-flex mt-sm-6">
      <div class="take-pass-reason-list mt-3 mt-sm-0">
        <!-- take reasons -->
        <div class="font-weight-bold">
          <v-icon v-text="`$vuetify.icons.thumbsUpFull`" />
          <span class="ml-3" v-text="`Things we loved about your items`" />
        </div>
        <div>
          <v-list class="ml-6" dense>
            <v-list-item
              v-for="reason of takeReasons"
              :key="`summary-take-${reason}`"
            >
              <v-list-item-content v-text="reason" />
            </v-list-item>
          </v-list>
        </div>
      </div>
      <!-- pass reasons -->
      <div class="take-pass-reason-list mt-3 mt-sm-0">
        <div class="font-weight-bold">
          <v-icon v-text="`$vuetify.icons.thumbsDownFull`" />
          <span class="ml-3" v-text="`Reasons we passed on some items`" />
        </div>
        <div>
          <v-list class="ml-6" dense>
            <v-list-item
              v-for="reason of passReasons"
              :key="`summary-pass-${reason}`"
            >
              <v-list-item-content v-text="reason" />
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style scope lang="scss">
.take-pass-reason-list {
  flex-basis: 50%;
}
</style>
