<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  minLength,
  requiredUnless,
} from "vuelidate/lib/validators";
import { upperFirst, debounce } from "lodash";

import { isSameMinute, parseISO } from "date-fns";
import { hasSnackbarAccess } from "@/mixins/ui";

// Paper signature is not validated, but there is a validation to have signature if paper signature is not true.
// - we have this validator (and use it for localPaperSignature field) so that handleUpdate method can function generically
const paperSignatureValidator = (value) => true;

export default {
  name: "BuyDialogMetaForm",
  components: {
    BuyDetailContainerInput: () => import("./BuyDetailContainerInput"),
    PickupTimeSelect: () => import("./PickupTimeSelect"),
  },
  mixins: [validationMixin, hasSnackbarAccess],
  inheritAttrs: false,
  inject: ["registerBuyDialogForm"],
  props: {
    containerNum: {
      type: Number,
      default: 0,
    },
    containerDescription: {
      type: String,
      default: "",
    },
    signatureFileName: {
      type: String,
      default: "",
    },
    signatureUrl: {
      type: String,
      default: "",
    },
    paperSignature: {
      type: Boolean,
    },
    estimatedPickupAt: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    storeId: { type: [Number, String], default: undefined },
    showSignatureField: { type: Boolean, default: true },
  },
  data: (vm) => ({
    localContainerNum: vm.containerNum || 0,
    localContainerDescription: vm.containerDescription,
    localEstimatedPickupAt: vm.estimatedPickupAt,
    localSignatureFileName: vm.signatureFileName,
    localPaperSignature: vm.paperSignature,
    loading: {
      containerNum: false,
      containerDescription: false,
      paperSignature: false,
      estimatedPickupAt: false,
    },
  }),
  validations: {
    localContainerNum: {
      required,
      minValue: minValue(1),
    },
    localContainerDescription: {
      required,
      minLength: minLength(3),
    },
    localEstimatedPickupAt: {
      required,
      // requiredUnless(function() {
      //   return this.status !== "pending";
      // }),
    },
    localSignatureFileName: {
      // This should not be required if `paper signature` is checked
      // or if the buy is in a stage later than 'pending'
      required: requiredUnless(function () {
        return this.localPaperSignature;
      }),
    },
    // Paper signature is not validated, but there is a validation to have signature if paper signature is not true.
    // - we have this validator (and use it for localPaperSignature field) so that handleUpdate method can function generically
    localPaperSignature: {
      paperSignatureValidator,
    },
  },
  computed: {
    containerDescriptionErrors() {
      const errors = [];
      const field = this.$v.localContainerDescription;
      !field.required && errors.push("Description is required.");
      !field.minLength && errors.push("Description is too short.");
      return errors;
    },
    isValid() {
      return !this.$v.$invalid;
    },
  },
  watch: {
    paperSignature() {
      this.$v.localSignatureFileName.$touch();
    },
    signatureFileName(v) {
      this.$v.localSignatureFileName.$model = v;
      this.$v.localSignatureFileName.$touch();
    },
    loading: {
      handler(v) {
        this.$emit(
          "loading",
          Object.values(v).some((e) => e)
        );
      },
      deep: true,
    },
  },
  mounted() {
    // Once we're mounted, tell the parent
    this.registerBuyDialogForm("metaForm", this);
  },
  methods: {
    // Public

    // Transform a prop name into a local data name
    // e.g., containerNum -> localContainerNum
    localDataName(field) {
      return `local${upperFirst(field)}`;
    },
    // Returns true if props.field === data.field
    fieldHasNotChanged(field) {
      if (field.match(/pickup/i))
        return isSameMinute(
          parseISO(this[field]),
          parseISO(this[this.localDataName(field)])
        );
      return this[field] === this[this.localDataName(field)];
    },
    // If the field is valid and the field's value differs from its initial value,
    // update the buy
    handleUpdate(field) {
      // Guard
      // Ensure the field passes validation AND the field value has changed
      if (
        this.$v[this.localDataName(field)].$invalid ||
        this.fieldHasNotChanged(field)
      )
        return;

      // Toggle loading for the field
      this.loading[field] = true;

      this.handleUpdateDebunced(field);
    },
    handleUpdateDebunced: debounce(async function (field) {
      this.$emit("update:field", field, this[this.localDataName(field)], () => {
        this.loading[field] = false;
      });
    }, 300),
  },
};
</script>

<template>
  <div>
    <div class="d-sm-flex flex-row">
      <div class="d-flex flex-column form-column mr-sm-3">
        <div class="d-flex">
          <h5 class="text-h6 mr-6">Containers</h5>
          <!-- # containers -->
          <BuyDetailContainerInput
            v-model="localContainerNum"
            :error="$v.localContainerNum.$invalid"
            :loading="loading.containerNum"
            @input="() => handleUpdate('containerNum')"
          />
        </div>
        <div class="mt-sm-3 mt-1">
          <!-- container description -->
          <v-text-field
            v-model="localContainerDescription"
            dense
            label="Description"
            :loading="loading.containerDescription"
            outlined
            name="description"
            :error-messages="containerDescriptionErrors"
            @input="handleUpdate('containerDescription')"
          />
        </div>
      </div>
      <div class="d-flex flex-column form-column">
        <div class="d-flex">
          <h4
            class="text-h6 mr-6 time-signature-title-column"
            v-text="`Quoted time`"
          />
          <!-- pickup time -->
          <PickupTimeSelect
            v-model="localEstimatedPickupAt"
            required
            :store-id="storeId"
            :loading="loading.estimatedPickupAt"
            @input="handleUpdate('estimatedPickupAt')"
          />
        </div>
        <div
          v-if="!signatureUrl && showSignatureField"
          class="d-flex mt-sm-6 mt-2"
        >
          <h5 class="text-h6 mr-6 time-signature-title-column">Signature</h5>
          <div class="d-flex flex-column">
            <div
              v-if="!localPaperSignature && !signatureFileName"
              class="font-italic mt-1"
              v-text="`Waiting on customer signature`"
            />
            <v-checkbox
              v-show="!signatureFileName"
              v-model="localPaperSignature"
              :class="{
                'mt-0': !!localPaperSignature,
                'mt-2': !localPaperSignature,
              }"
              label="Paper signature"
              @change="handleUpdate('paperSignature')"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="signatureUrl && showSignatureField" class="d-flex">
      <h5 class="text-h6 mr-6 time-signature-title-column">Signature</h5>
      <img
        class="py-2"
        :src="signatureUrl"
        :alt="'Customer signature'"
        style="max-height: 95px"
      />
    </div>
  </div>
</template>

<style scoped>
.form-column {
  flex-basis: 50%;
}

.time-signature-title-column {
  flex-basis: 130px;
}
</style>
