<script>
import gql from "graphql-tag";
import BuyDialogCustomerForm from "./BuyDialogCustomerForm";
import BuyDialogMetaForm from "./BuyDialogMetaForm";
import BuyContainers from "./BuyContainers";
import BuyTimeline from "./BuyTimeline";
import SorterSelector from "./SorterSelector";
import BuyDialogOfferForm from "./BuyDialogOfferForm";
import { START_BUY } from "../graphql";
import {
  injectActiveEmployee,
  hasActiveEmployeeIdAccess,
} from "@/mixins/employee";
import { hasSnackbarAccess } from "@/mixins/ui";

const BUY_DIALOG_OFFER = gql`
  query BUY_DIALOG_OFFER($id: ID!) {
    buy(id: $id) {
      id
      status
      quoteItems
      quoteAmount
      internalNote
      sorterId
      takeReasons
      passReasons
      completedAt
      estimatedPickupAt
    }
  }
`;

const BUY_DIALOG_META = gql`
  query BUY_DIALOG_META($id: ID!) {
    buy(id: $id) {
      id
      containerNum
      containerDescription
      signatureFileName
      signatureUrl
      estimatedPickupAt
      checkedInAt
      checkedInByEmployeeId
      status
      transId
      buyId: id
      sorterId
      quoteItems
    }
  }
`;

const BUY_DIALOG_CUSTOMER = gql`
  query BUY_DIALOG_CUSTOMER($id: ID!, $storeId: ID!, $buyId: ID!) {
    customer(id: $id, storeId: $storeId) {
      id
      firstName
      lastName
      phoneNumber
      email
      flagged
      flagReason
      customerId: id
      driverLicense {
        id
        address1
        address2
        city
        state
        postalCode
      }
    }
    buy(id: $buyId) {
      id
      status
      licenseVerifiedAt
    }
  }
`;

export default {
  name: "BuyDialogOpenContent",
  inject: ["registerBuyDialogForm"],
  components: {
    BuyContainers,
    BuyTimeline,
    SorterSelector,
    BuyDialogOfferForm,
    BuyDialogMetaForm,
    BuyDialogCustomerForm,
  },
  mixins: [hasSnackbarAccess, injectActiveEmployee, hasActiveEmployeeIdAccess],
  props: {
    customerId: {
      type: String,
      default: undefined,
    },
    buyStatus: {
      type: String,
      default: undefined,
    },
    buyId: {
      type: String,
      default: undefined,
    },
    storeId: {
      type: [String, Number],
      default: undefined,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingSorter: false,
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  apollo: {
    buyMetaData: {
      loadingKey: "buyMetaLoading",
      query: BUY_DIALOG_META,
      variables() {
        return {
          id: this.buyId,
        };
      },
      skip() {
        return !this.buyId;
      },
      fetchPolicy: "cache-and-network",
      update(response) {
        return response.buy;
      },
    },
    customerBuyData: {
      loadingKey: "customerBuyLoading",
      query: BUY_DIALOG_CUSTOMER,
      variables() {
        return {
          id: this.customerId,
          storeId: this.storeId,
          buyId: this.buyId,
        };
      },
      skip() {
        return !this.customerId;
      },
      fetchPolicy: "cache-and-network",
      update(response) {
        return {
          customer: response.customer,
          buy: response.buy,
        };
      },
    },
    buyOfferData: {
      loadingKey: "offerDataLoading",
      query: BUY_DIALOG_OFFER,
      variables() {
        return {
          id: this.buyId,
        };
      },
      skip() {
        return !this.buyId;
      },
      fetchPolicy: "cache-and-network",
      update(response) {
        return response.buy;
      },
    },
  },
  methods: {
    // Mutation to start sorting a buy
    // Update the cache for the buy
    async startSorting() {
      // TODO - watch and output loading when loadingSorter is loading also
      this.loadingSorter = true;
      try {
        await this.$apollo.mutate({
          mutation: START_BUY,
          variables: { id: this.buyId, employeeId: this.activeEmployeeId },
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while setting the buy sorter: ${error}`,
        });
      }
      this.loadingSorter = false;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="buyMetaData">
      <BuyDialogCustomerForm
        v-if="customerBuyData && showEdit"
        v-bind="{
          ...customerBuyData.customer,
          customerId,
          buyId,
          status: buyStatus,
          licenseVerified: !!customerBuyData.buy.licenseVerifiedAt,
        }"
        @loading="(v) => $emit('loading', v)"
        @update:field="
          (field, value, finishedCallback) =>
            $emit('update:customerField', field, value, finishedCallback)
        "
        @update:idField="
          (idUpdate, finishedCallback) =>
            $emit('update:idField', idUpdate, finishedCallback)
        "
        @update:idVerifyField="
          (last4, finishedCallback) =>
            $emit('update:idVerifyField', last4, finishedCallback)
        "
      />
      <BuyDialogMetaForm
        v-if="buyMetaData && showEdit"
        :show-signature-field="false"
        class="mt-sm-6 mt-3"
        v-bind="{ ...buyMetaData, buyId: buyId, storeId: storeId }"
        @loading="(v) => $emit('loading', v)"
        @update:field="
          (field, value, finishedCallback) =>
            $emit('update:buyField', field, value, finishedCallback)
        "
      />

      <BuyContainers
        v-if="!buyMetaData.sorterId || !isSmallScreen"
        :number="buyMetaData.containerNum"
        :description="buyMetaData.containerDescription"
      />

      <BuyTimeline
        v-if="!buyMetaData.sorterId"
        :buy-id="buyId"
        :class="{ 'mt-sm-6 mt-3': !buyMetaData.sorterId }"
      />

      <div class="mt-sm-6 mt-3">
        <v-btn
          v-if="!buyMetaData.sorterId"
          class="mr-2"
          :loading="loadingSorter"
          color="secondary"
          @click.stop="startSorting"
          v-text="'Start sorting'"
        />
        <div v-else class="mr-2 d-sm-flex align-center justify-start">
          <SorterSelector :buy-id="buyId" edit class="sorter-and-items" />

          <div
            v-if="buyOfferData && buyOfferData.quoteItems"
            class="sorter-and-items"
          >
            We would like to offer
            <span class="font-weight-bold">{{
              $filters.dollars(buyOfferData.quoteAmount)
            }}</span>
            for
            <span class="font-weight-bold">{{
              buyOfferData.quoteItems || 0
            }}</span>
            items
          </div>
          <div v-else class="sorter-and-items text-subtitle-2">
            No items have been entered into DRS
          </div>
        </div>
      </div>

      <!-- Show the offer if the buy is open and there is a sorter OR if the buy is past complete -->
      <div v-if="buyOfferData && buyMetaData">
        <BuyDialogOfferForm
          v-if="buyMetaData.sorterId"
          class="mt-sm-6 mt-3"
          v-bind="{ ...buyOfferData, buyId: buyId, storeId: storeId }"
          @loading="(v) => $emit('loading', v)"
        />
      </div>

      <div v-if="!!buyMetaData.sorterId && isSmallScreen">
        <v-divider class="mb-3 timeline-divider" />
        <BuyContainers
          class="mb-3"
          :number="buyMetaData.containerNum"
          :description="buyMetaData.containerDescription"
        />
        <BuyTimeline
          :buy-id="buyId"
          :class="{ 'mt-sm-6 mt-3': !buyMetaData.sorterId }"
        />
      </div>
    </div>
  </div>
</template>

<style scope lang="scss">
.sorter-and-items {
  flex-basis: 50%;
}

.timeline-divider {
  margin-left: -24px;
  max-width: none;
  width: calc(100% + 48px);
}
</style>
