<script>
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import {
  debounce,
  unionBy,
  sortBy,
  filter,
  includes,
  map,
  xor,
  uniq,
  clone,
} from "lodash";
import gql from "graphql-tag";
import { STORE_BUY_REASONS } from "../graphql";
import { injectClockedInEmployees } from "@/mixins/employee";
export default {
  name: "BuyDialogOfferForm",
  components: {
    BuyReasonAutocomplete: () => import("./BuyReasonAutocomplete"),
  },
  mixins: [validationMixin, injectClockedInEmployees],
  inject: ["registerBuyDialogForm"],
  inheritAttrs: false,
  props: {
    storeId: {
      type: String,
      default: undefined,
    },
    buyId: {
      type: String,
      default: undefined,
    },
    customerId: {
      type: String,
      default: undefined,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    quoteItems: {
      type: Number,
      default: 0,
    },
    quoteAmount: {
      type: Number,
      default: 0,
    },
    internalNote: {
      type: String,
      default: undefined,
    },
    status: {
      type: String,
      default: "",
    },
    canEditReasons: {
      type: Boolean,
      default: true,
    },
    takeReasons: {
      type: Array,
      default: () => [],
    },
    passReasons: {
      type: Array,
      default: () => [],
    },
  },
  data(vm) {
    return {
      loading: {
        internalNote: false,
        takeReasons: false,
        passReasons: false,
      },
      // We note when user is chaning something so that we are able to disable the action button
      // while data is not updated on server
      hasChangeInProgress: {
        internalNote: false,
        takeReasons: false,
        passReasons: false,
      },
      storeTakeReasons: [],
      storePassReasons: [],
      // Use `data` to store local copy of array of values
      // Corresponding watcher debounces backend updates
      // we clone it here so that change to local does not override original array
      localTakeReasons: clone(vm.takeReasons),
      // Use `data` to store local copy of array of values
      // Corresponding watcher debounces backend updates
      // we clone it here so that change to local does not override original array
      localPassReasons: clone(vm.passReasons),
      // Store internalNote in data
      localInternalNote: vm.internalNote,

      internalTakeReasonsExpanded: false,
      internalPassReasonsExpanded: false,
    };
  },
  validations: {
    localInternalNote: {
      required: requiredIf(function () {
        if (this.localTakeReasons && this.localTakeReasons.length > 0) {
          return false;
        }
        if (this.localPassReasons && this.localPassReasons.length > 0) {
          return false;
        }

        return true;
      }),
    },
    localTakeReasons: {
      required: requiredIf(function () {
        if (this.localPassReasons && this.localPassReasons.length > 0) {
          return false;
        }

        if (!!this.localInternalNote) {
          return false;
        }

        return true;
      }),
    },
    localPassReasons: {
      required: requiredIf(function () {
        if (this.localTakeReasons && this.localTakeReasons.length > 0) {
          return false;
        }

        if (!!this.localInternalNote) {
          return false;
        }

        return true;
      }),
    },
  },
  apollo: {
    storeTakeReasons: {
      query: STORE_BUY_REASONS,
      variables() {
        return { storeId: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
      update(response) {
        return response.storeBuyReasons.takeReasons;
      },
    },
    storePassReasons: {
      query: STORE_BUY_REASONS,
      variables() {
        return { storeId: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
      update(response) {
        return response.storeBuyReasons.passReasons;
      },
    },
  },
  computed: {
    takeReasonsExpanded() {
      if (!this.isSmallScreen) {
        return true;
      }

      return this.internalTakeReasonsExpanded;
    },
    passReasonsExpanded() {
      if (!this.isSmallScreen) {
        return true;
      }

      return this.internalPassReasonsExpanded;
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    // A union of the store's most used pass reasons and any selected pass
    // reasons on the buy which may not show up in the most used
    passOptions() {
      return sortBy(
        unionBy(
          this.storePassReasons,
          this.passReasons,
          this.localPassReasons,
          (reason) => reason
        ),
        (reason) => reason
      );
    },
    selectedPassOptions() {
      return filter(this.passOptions, (option) =>
        includes(this.localPassReasons, option)
      );
    },
    // A union of the store's most used take reasons and any selected take
    // reasons on the buy which may not show up in the most used
    takeOptions() {
      return sortBy(
        unionBy(
          this.storeTakeReasons,
          this.takeReasons,
          this.localTakeReasons,
          (reason) => reason
        ),
        (reason) => reason
      );
    },
    selectedTakeOptions() {
      return filter(this.takeOptions, (option) =>
        includes(this.localTakeReasons, option)
      );
    },
    isValid() {
      return (
        !this.$v.$invalid &&
        !Object.values(this.hasChangeInProgress).some((e) => e)
      );
    },
  },
  watch: {
    localInternalNote(v) {
      this.hasChangeInProgress.internalNote = true;
    },
    // Emit loading events
    loading: {
      handler(v) {
        this.$emit(
          "loading",
          Object.values(v).some((e) => e)
        );
      },
      deep: true,
    },
  },
  created() {
    // Mount debounced functions. One for simple and quick updates,
    // the other for longer debounce times (checkboxes)
    this.updateReasonsLongDebounce = debounce(this.updateReasons, 600);
  },
  mounted() {
    // Once we're mounted, tell the parent
    this.registerBuyDialogForm("offerForm", this);
  },
  methods: {
    // Does `value` (the assumingly new value) equal $props[field] (the old value) ?
    // *Note* - This won't work for Objects or Arrays. Could always use
    //          lodash's `isEqual/2` if we need to, which does a deep
    //          comparison.
    fieldHasNotChanged(field, value) {
      return this[field] === value;
    },
    // updateField
    // A buy update, updating buy[field] = value
    // -- Params:
    //    field|string (required): the actual field name on the record (internalNote)
    //    value|any (required):    the updated value ('dis a sweet internal note')
    async updateField(field, value) {
      this.loading[field] = true;

      // Guards - if the value hasn't changed, don't try to update it.
      if (this.fieldHasNotChanged(field, value)) {
        this.loading[field] = false;
        return;
      }

      try {
        const {
          data: {
            updateBuy: { errors: updateErrors },
          },
          errors,
        } = await this.$apollo.mutate({
          mutation: gql`
            mutation BUY_DIALOG_OFFER_FORM_UPDATE_BUY($input: UpdateBuyInputObject!) {
              updateBuy(input: $input) {
                buy {
                  id
                  ${field}
                }
                errors
              }
            }
          `,
          variables: { input: { id: this.buyId, [field]: value } },
        });
        // Network errors
        if (errors && errors.length) {
          const msg = errors.map(({ message }) => message).join("; ");
          throw new Error(msg);
        }
        // Rails errors
        if (updateErrors && updateErrors.length) {
          throw new Error(updateErrors.join("; "));
        }

        this.hasChangeInProgress[field] = false;
      } catch (error) {
        this.hasChangeInProgress[field] = false;
        alert(`Error updating buy: ${error}`);
      }
      this.loading[field] = false;
    },
    // Handler for the @input event on the `take` BuyReasonAutocomplete
    handleTakeReasonInput(reason) {
      if (this.localTakeReasons.indexOf(reason.name) < 0) {
        this.localTakeReasons.push(reason.name);
        // In this case (autocomplete), update reasons imediatelly, not on debounce
        this.updateReasons("take");
      }
    },
    updateLocalTakeReasons(reasons) {
      this.localTakeReasons = reasons;
      this.updateReasonsLongDebounce("take");
    },
    // Handler for the @input event on the `pass` BuyReasonAutocomplete
    handlePassReasonInput(reason) {
      if (this.localPassReasons.indexOf(reason.name) < 0) {
        this.localPassReasons.push(reason.name);
        // In this case (autocomplete), update reasons imediatelly, not on debounce
        this.updateReasons("pass");
      }
    },
    updateLocalPassReasons(reasons) {
      this.localPassReasons = reasons;
      this.updateReasonsLongDebounce("pass");
    },
    // Shared code for the two local[Type]Reasons watchers
    // Updating the resons via click checkboxes
    async updateReasons(type) {
      if (
        xor(this.takeReasons, this.localTakeReasons).length <= 0 &&
        xor(this.passReasons, this.localPassReasons).length <= 0
      ) {
        // Do not update if nothing changed
        return;
      }

      // build variables object
      const variables = {
        input: {
          id: this.buyId,
          storeId: this.storeId,
          // Ensure we do no repeate some pass or take reason
          takeReasons: uniq(this.localTakeReasons),
          passReasons: uniq(this.localPassReasons),
        },
      };

      // Use field to setup loaders
      const field = `${type}Reasons`;
      this.hasChangeInProgress[field] = true;
      this.loading[field] = true;

      try {
        const {
          data: {
            buyUpdateReasons: { buy: updatedBuy, errors: updateErrors },
          },
          errors,
        } = await this.$apollo.mutate({
          mutation: gql`
            mutation BUY_UPDATE_REASONS($input: UpdateReasonsInput!) {
              buyUpdateReasons(input: $input) {
                buy {
                  id
                  takeReasons
                  passReasons
                }
                errors
              }
            }
          `,
          variables,
        });

        // Network errors
        if (errors && errors.length) {
          const msg = errors.map(({ message }) => message).join("; ");
          throw new Error(msg);
        }

        // Rails errors
        if (updateErrors && updateErrors.length) {
          throw new Error(updateErrors.join("; "));
        }

        this.hasChangeInProgress[field] = false;
        this.loading[field] = false;
      } catch (error) {
        this.hasChangeInProgress[field] = false;
        this.loading[field] = false;
        alert(`Error updating buy: ${error}`);
      }
    },
    toggleTakeReasons() {
      if (!this.isSmallScreen) {
        // Nothing to do on desktop
        return;
      }

      this.internalTakeReasonsExpanded = !this.internalTakeReasonsExpanded;

      if (this.internalTakeReasonsExpanded) {
        this.internalPassReasonsExpanded = false;
      }
    },
    togglePassReasons() {
      if (!this.isSmallScreen) {
        // Nothing to do on desktop
        return;
      }

      this.internalPassReasonsExpanded = !this.internalPassReasonsExpanded;

      if (this.internalPassReasonsExpanded) {
        this.internalTakeReasonsExpanded = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="!isSmallScreen">
      <v-text-field
        v-model="localInternalNote"
        label="Enter notes"
        outlined
        dense
        :loading="loading.internalNote"
        rows="3"
        @blur="updateField('internalNote', localInternalNote)"
      />
    </div>

    <!-- TODO - this should be moved, so that we have notes and reasons forms/summary separated -->
    <!-- buy reasons -->
    <div v-if="!canEditReasons" class="d-sm-flex mt-sm-6">
      <div class="take-pass-reason-list mt-3 mt-sm-0">
        <!-- take reasons -->
        <div class="font-weight-bold">
          <v-icon v-text="`$vuetify.icons.thumbsUpFull`" />
          <span class="ml-3" v-text="`Things we loved about your items`" />
        </div>
        <div>
          <v-list class="ml-6" dense>
            <v-list-item
              v-for="reason of localTakeReasons"
              :key="`summary-take-${reason}`"
            >
              <v-list-item-content v-text="reason" />
            </v-list-item>
          </v-list>
        </div>
      </div>
      <!-- pass reasons -->
      <div class="take-pass-reason-list mt-3 mt-sm-0">
        <div class="font-weight-bold">
          <v-icon v-text="`$vuetify.icons.thumbsDownFull`" />
          <span class="ml-3" v-text="`Reasons we passed on some items`" />
        </div>
        <div>
          <v-list class="ml-6" dense>
            <v-list-item
              v-for="reason of localPassReasons"
              :key="`summary-pass-${reason}`"
            >
              <v-list-item-content v-text="reason" />
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>

    <!-- take/pass reasons -->
    <div v-if="canEditReasons" class="d-sm-flex">
      <div class="take-pass-reason-list mt-3 mt-sm-0">
        <div class="d-flex align-center">
          <v-icon
            class="mr-3"
            @click="toggleTakeReasons"
            v-text="`$vuetify.icons.thumbsUpFull`"
          />
          <span @click="toggleTakeReasons" v-text="`We loved`" />
          <v-btn v-if="isSmallScreen" icon @click="toggleTakeReasons">
            <v-icon v-if="!takeReasonsExpanded">$plus</v-icon>
            <v-icon v-if="!!takeReasonsExpanded">$minus</v-icon>
          </v-btn>
          <v-progress-circular
            v-show="loading.takeReasons"
            class="ml-3"
            indeterminate
            color="primary"
            size="24"
          />
        </div>
        <div v-if="!takeReasonsExpanded" class="ml-9">
          <div
            v-for="takeOption of selectedTakeOptions"
            :key="`take-${takeOption}`"
            class="mb-2"
          >
            {{ takeOption }}
          </div>
        </div>
        <!-- selectors -->
        <div v-if="takeReasonsExpanded" class="ml-8">
          <template v-for="takeOption of takeOptions">
            <v-checkbox
              :key="`take-${takeOption}`"
              :input-value="localTakeReasons"
              :label="takeOption"
              :value="takeOption"
              multiple
              hide-details
              dense
              @change="updateLocalTakeReasons"
            />
          </template>
          <BuyReasonAutocomplete
            class="mt-3 mr-8"
            type="take"
            @input="handleTakeReasonInput"
          />
        </div>
      </div>
      <!-- pass -->
      <div class="take-pass-reason-list mt-5 mt-sm-0">
        <div class="d-flex align-center">
          <v-icon
            class="mr-3"
            @click="togglePassReasons"
            v-text="`$vuetify.icons.thumbsDownFull`"
          />
          <span @click="togglePassReasons" v-text="`We passed`" />
          <v-btn v-if="isSmallScreen" icon @click="togglePassReasons">
            <v-icon v-if="!passReasonsExpanded">$plus</v-icon>
            <v-icon v-if="!!passReasonsExpanded">$minus</v-icon>
          </v-btn>
          <v-progress-circular
            v-show="loading.passReasons"
            class="ml-3"
            indeterminate
            color="primary"
            size="24"
          />
        </div>
        <div v-if="!passReasonsExpanded" class="ml-9">
          <div
            v-for="passOption of selectedPassOptions"
            :key="`pass-${passOption}`"
            class="mb-2"
          >
            {{ passOption }}
          </div>
        </div>
        <!-- selectors -->
        <div v-if="passReasonsExpanded" class="ml-8">
          <template v-for="passOption of passOptions">
            <v-checkbox
              :key="`pass-${passOption}`"
              :input-value="localPassReasons"
              :label="passOption"
              :value="passOption"
              multiple
              hide-details
              dense
              @change="updateLocalPassReasons"
            />
          </template>
          <BuyReasonAutocomplete
            class="mt-3 mr-8"
            type="pass"
            @input="handlePassReasonInput"
          />
        </div>
      </div>
    </div>
    <div v-if="isSmallScreen" class="mt-5">
      <v-text-field
        v-model="localInternalNote"
        label="Enter notes"
        outlined
        dense
        :loading="loading.internalNote"
        rows="3"
        @blur="updateField('internalNote', localInternalNote)"
      />
    </div>
  </div>
</template>

<style scope lang="scss">
.take-pass-reason-list {
  flex-basis: 50%;
}
</style>
